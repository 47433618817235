export const parseValidationErrorsFromResponse = (errors: any) => {
  const validationErrors: { name: string; errors: string[] }[] = [];

  if (errors?.response?.errors && Array.isArray(errors?.response?.errors)) {
    errors?.response?.errors.forEach((itemError: any) => {
      if (itemError?.extensions?.validation) {
        Object.keys(itemError?.extensions?.validation).forEach((itemKey) => {
          const parsedKey = itemKey.split('.');
          const [key] = parsedKey;
          const currentItemErrors = itemError?.extensions?.validation[itemKey];
          const [currentError] = currentItemErrors;

          validationErrors.push({
            name: key,
            errors: [currentError as any],
          });
        });
      }
    });
  }

  return validationErrors;
};
