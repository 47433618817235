import { bool, envsafe, str } from 'envsafe';
import { ConfigType } from 'providers';

export const browserEnv = envsafe(
  {
    NEXT_PUBLIC_APP_CODE: str({
      devDefault: 'TBA-WEB',
      input: process.env.NEXT_PUBLIC_APP_CODE,
    }),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: str({
      input: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_GOOGLE_ADS_ID: str({
      input: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_CMS_API_HOST_URL: str({
      devDefault: 'https://cms.beta.marinedatacloud.com',
      input: process.env.NEXT_PUBLIC_CMS_API_HOST_URL,
    }),
    NEXT_PUBLIC_GA_ID: str({
      input: process.env.NEXT_PUBLIC_GA_ID,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_MDC_API_HOST_URL: str({
      devDefault: 'https://api.beta.marinedatacloud.com',
      input: process.env.NEXT_PUBLIC_MDC_API_HOST_URL,
    }),
    NEXT_PUBLIC_PASSWORD_PROTECT: bool({
      input: process.env.NEXT_PUBLIC_PASSWORD_PROTECT,
    }),
    NEXT_PUBLIC_SENTRY_DNS: str({
      input: process.env.NEXT_PUBLIC_SENTRY_DNS,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_SENTRY_ENV: str({
      input: process.env.NEXT_PUBLIC_SENTRY_ENV,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_SENTRY_ENABLED: bool({
      input: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: str({
      input: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO: str({
      input: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO,
    }),
    NEXT_PUBLIC_MDC_ACCOUNT_URL: str({
      input: process.env.NEXT_PUBLIC_MDC_ACCOUNT_URL,
    }),
    NEXT_PUBLIC_AUTH0_DOMAIN: str({
      input: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    }),
    NEXT_PUBLIC_AUTH0_AUDIENCE: str({
      input: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    }),
    NEXT_PUBLIC_AUTH0_CLIENT_ID: str({
      input: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    }),
    NEXT_PUBLIC_THEBOATAPP_URL: str({
      input: process.env.NEXT_PUBLIC_THEBOATAPP_URL,
    }),
    NEXT_PUBLIC_MARINE_DATA_CLOUD_URL: str({
      input: process.env.NEXT_PUBLIC_MARINE_DATA_CLOUD_URL,
    }),
    NEXT_PUBLIC_ZOHO_CHAT_KEY: str({
      input: process.env.NEXT_PUBLIC_ZOHO_CHAT_KEY,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_ZOHO_RUM_KEY: str({
      input: process.env.NEXT_PUBLIC_ZOHO_RUM_KEY,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_RECAPTCHA_KEY: str({
      input: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
    }),
  },
  {
    // As we're using plain JS, it's useful to use strict mode which prevents accessing undefined props
    strict: true,
  },
);

export function getAppConfiguration(): ConfigType {
  return {
    googleMapApiKey: browserEnv.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    googleAnalyticId: browserEnv.NEXT_PUBLIC_GA_ID,
    googleAdsId: browserEnv.NEXT_PUBLIC_GOOGLE_ADS_ID,
    sentryDns: browserEnv.NEXT_PUBLIC_SENTRY_DNS,
    sentryEnv: browserEnv.NEXT_PUBLIC_SENTRY_ENV,
    sentryEnabled: browserEnv.NEXT_PUBLIC_SENTRY_ENABLED,
    sentryTracesSampleRate: browserEnv.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    auth0LogoutReturnTo: browserEnv.NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO,
    mdcApiHost: browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL,
    mdcFullApiUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/api/v2/`,
    mdcGraphQlUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/graphql`,
    mdcAccountUrl: browserEnv.NEXT_PUBLIC_MDC_ACCOUNT_URL,
    cmsApiHost: browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL,
    cmsFullApiUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/api`,
    cmsGraphQlUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/graphql`,
    auth0Domain: browserEnv.NEXT_PUBLIC_AUTH0_DOMAIN,
    auth0Audience: browserEnv.NEXT_PUBLIC_AUTH0_AUDIENCE,
    auth0ClientId: browserEnv.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    appCode: browserEnv.NEXT_PUBLIC_APP_CODE,
    theboatAppUrl: browserEnv.NEXT_PUBLIC_THEBOATAPP_URL,
    mdcLandingUrl: browserEnv.NEXT_PUBLIC_MARINE_DATA_CLOUD_URL,
    zohoSalesIqWidgetCode: browserEnv.NEXT_PUBLIC_ZOHO_CHAT_KEY,
    zohoRumKey: browserEnv.NEXT_PUBLIC_ZOHO_RUM_KEY,
    recaptchaKey: browserEnv.NEXT_PUBLIC_RECAPTCHA_KEY,
  };
}
