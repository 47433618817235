export const parseErrorsFromResponse = (errors: any) => {
  let errorMessage: string | undefined;

  if (errors?.response?.errors && Array.isArray(errors?.response?.errors)) {
    errors?.response?.errors.forEach((itemError: any) => {
      console.log('🚀 ~ file: parseErrorsFromResponse.ts:6 ~ errors?.response?.errors.forEach ~ itemError:', itemError);

      if (itemError?.message?.length) {
        errorMessage = itemError?.message;
      }
    });
  }

  return errorMessage;
};
